<template>
    <div class='constitution'>
        <div class="detection">
            <div class="testItem" v-for="(item, index) in healData" :key="index">
                <div class="title" v-if="index == '0'">
                    <div class="text">健康问卷</div>
                    <div class="subtitle">目前身体的状况</div>
                </div>
                <!-- 问卷内容 -->
                <div class="questionnaire">
                    <div class="questTitle">{{ item.Title }}</div>
                    <!-- 单选框 -->
                    <van-radio-group v-model="item.value" direction="horizontal" v-if="item.type == 'radio'">
                        <van-radio :name="el.Id" v-for="el in item.Content" :key="el.Id" checked-color="#56D1E6">{{ el.Title }}</van-radio>
                    </van-radio-group>

                     <!-- 复选框 -->
                    <van-checkbox-group v-model="item.value" direction="horizontal" v-else @change="checkChange">
                        <van-checkbox :name="el.Id" v-for="el in item.Content" :key="el.Id" checked-color="#56D1E6">{{ el.Title }}</van-checkbox>
                    </van-checkbox-group>
                </div>
            </div>

            <div class="btn">
                <van-button round type="info" @click="submit">提交</van-button>
            </div>
        </div>
    </div>
</template>

<script>
import { GetHealthQuestionList } from '@/api/index'

export default {
  components: {

  },
  data () {
    return {
      dietTyerapyUrl: `${this.$imgDomain}/www/HealthingCore/dietTyerapy2.png`,
      healData: [],
      userData: {}
    }
  },
  props: {

  },
  created () {
    this.userData = JSON.parse(this.$query('userData'))
    console.log(this.userData, 'this.userData')
    this.getHealthQuestionListData(this.userData)
  },
  mounted () {

  },
  methods: {
    getHealthQuestionListData (data) {
      const parmas = {
        sex: data.sex
      }
      GetHealthQuestionList(parmas).then(res => {
        const { data } = res
        if (data.HealData) {
          this.healData = data.HealData
        }
      })
    },
    async submit () {
      const result = await this.checkSpecial()
      const result1 = await this.checkThyroid()
      const result2 = await this.checkTypeNum()
      if (result && result1 && result2) {
        const healData = []
        this.healData.forEach(item => {
          if (item.value && item.value.length > 0) {
            item.value.forEach(el => {
              healData.push({ Id: el })
            })
          }
        })
        this.$toast.success('提交成功')
        this.$router.push(
          {
            path: '/physiqueTest',
            query: {
              userData: JSON.stringify(this.userData),
              healData: JSON.stringify(healData)
            }
          }
        )
      }
    },
    async checkChange () {
      await this.checkSpecial()
      await this.checkThyroid()
      await this.checkTypeNum()
    },
    // 校验选择类型数量 最多3个
    checkTypeNum () {
      return new Promise((resolve, reject) => {
        const checkArr = []
        this.healData.forEach(item => {
          if (item.value && item.value.length > 0) {
            checkArr.push(item)
          }
        })
        if (checkArr.length > 3) {
          this.$toast.fail('最多可勾选3种大类别')
          resolve(false)
        } else {
          resolve(true)
        }
      })
    },
    // 校验 肠道特殊选项
    checkSpecial () {
      const that = this
      return new Promise((resolve, reject) => {
        const findItem = that.healData.find(el => {
          if (el.title == '肠道' && el.value && el.value.length > 0) {
            return el
          }
        })
        if (findItem) {
          const newList = findItem.content.filter((el) =>
            findItem.value.includes(el.id)
          )
          const result1 = newList.find(el => el.title == '经常便秘')
          const result2 = newList.find(el => el.title == '经常腹泻')

          if (result1 && result2) {
            that.$toast.fail('便秘和腹泻只能二选一')
            resolve(false)
          } else {
            resolve(true)
          }
        } else {
          resolve(true)
        }
      })
    },
    // 校验 甲状腺特殊选项
    checkThyroid () {
      const that = this
      return new Promise(function (resolve, reject) {
        const findItem = that.healData.find(el => {
          if (el.title == '甲状腺' && el.value && el.value.length > 0) {
            return el
          }
        })
        if (findItem) {
          const newList = findItem.content.filter((el) =>
            findItem.value.includes(el.id)
          )
          const result1 = newList.find(el => el.title == '甲减')
          const result2 = newList.find(el => el.title == '甲亢')

          if (result1 && result2) {
            that.$toast.fail('甲减和甲亢只能二选一')
            resolve(false)
          } else {
            resolve(true)
          }
        } else {
          resolve(true)
        }
      })
    }

  },
  watch: {

  }
}
</script>
<style lang='scss' scoped>
.constitution {
    background: url('https://gotocloud8-open-sx.oss-cn-hangzhou.aliyuncs.com/www/HealthingCore/dietTyerapy2.png') no-repeat;
    background-size: 100%;
    background-color: #F5F5F5;
    padding: 0 15px;
    .detection {
        // transform: translateY(80%);
        margin-top: 120%;
        .testItem {
            padding: 20px;
            border-radius: 10px;
            background: #fff;
            margin-bottom: 10px;
            .title {
                display: flex;
                flex-direction: column;
                align-items: center;
                padding: 20px 0;
                .text {
                    color: #333333;
                    font-weight: 700;
                    font-size: 20px;
                    position: relative;
                    margin-bottom: 30px;
                }
                .text::before,
                .text::after{
                    content: "";
                    position: absolute;
                    top: 10px;
                    width: 10px;
                    height: 10px;
                    border-radius: 5px;
                    background-color: #57D1E6;
                }
                .text::before {
                    left: -20px;
                }
                .text::after {
                    right: -20px;
                }
            }
            .subtitle {
                width: 100%;
                font-size: 18px;
                font-weight: 700;
            }
            .questionnaire {
                .questTitle {
                    color: #999999;
                    font-size: 16px;
                    font-weight: bold;
                }
                /deep/ .van-radio-group,
                /deep/ .van-checkbox-group {
                    display: flex;
                }
                /deep/ .van-radio,
                /deep/ .van-checkbox {
                    width: 50%;
                    margin: 15px 0 0;
                }
                /deep/ .van-radio__icon,
                /deep/ .van-checkbox__icon {
                    height: auto;
                    .van-icon {
                        width: 1em;
                        height: 1em;
                        line-height: 1;
                    }
                }
                /deep/ .van-radio__icon--checked + .van-radio__label,
                /deep/ .van-checkbox__icon--checked + .van-checkbox__label {
                    color: #56D1E6;
                }
                /deep/ .van-radio__label,
                /deep/ .van-checkbox__label{
                    font-size: 15px;
                    color: #333333;
                    font-weight: bold;
                }
            }
        }
    }

    .btn {
        text-align: center;
        padding: 40px 0;
        /deep/ .van-button {
            background: linear-gradient(-58deg,#6dd6da 0%, #52cfe6 100%);
            border: none;
            width: 200px;
            .van-button__text {
                font-size: 20px;
                font-weight: 700;
            }
        }
    }
}
</style>
